@import '../../../../themes/variables.scss';
h1.onoff-title {
  text-align: center;
  font-size: 63px;
  color: $theme-color-secondary;
  padding-top: 64px;
  padding-bottom: 32px;
  border-bottom: 1px solid;
  border-bottom-color: $gray;
}

div.onoff-content {
  width: 1150px;
  .mes-info {
    text-align: center;
    font-size: 30px;
  }
  .row {
    padding-left: 20px;
    padding-right: 20px;

    label {
      margin-top: 20px;
    }

    textarea {
      border-radius: 20px;
    }
  }
  .text-size-info {
    float: right;
    color: $gray;
    font-size: 14px;
  }
}

.onoff-button {
  color: $white;
  background-color: $primary;
  border-radius: 40px;
  margin: 20px !important;
  font-size: 25px;
  font-weight: 500;
  width: 300px;
  height: 72px;
  line-height: 28px;
}

.onoff-left {
  float: left;
}

.onoff-right {
  float: right;
  margin-right: 0px;
}

div.input-group.onoff {
  padding-right: 15 px;
}

div.onoff-info {
  padding-left: 32px;
  font-size: 25px;
}

hr.onoff {
  margin: 15px;
}

a.onoff-navbtns div {
  cursor: pointer;

  flex: 1;
  //margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onoff-help-link {
  display: block;
  padding-bottom: 60px;
}

.img-logo {
  max-width: 300px;
}

.img-envio {
  max-width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}

.add-file {
  margin-left: 100px;
  color: $primary;
}

.table-label {
  font-size: 25px;
  font-weight: 500;
  padding-bottom: 20px;
}

.resumo-tbody {
  font-size: 20px;
}

.table.table-striped.table-resumo {
  box-shadow: 0px 3px 5px $gray;
  td:first-child {
    max-width: 60px;
  }
  td:last-child {
    min-width: 100px;
  }
}

.images-label {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-top: 100px;
}

.onoff-img {
  padding: 5px;
  width: 250px;
}

.modal-onoff-busca-cliente {
  .modal-header {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    background-color: $primary;
    color: $white;

    button.close {
      color: $white !important;
    }
  }
  .modal-body {
    font-size: 14px;
    overflow-x: auto;
    tbody tr {
      cursor: pointer;
    }
    .tr-selecionado {
      background: $primary-selecionado;
      color: $white;
    }

    td {
      font-size: 14px;
    }
  }
  .button-close {
    border-radius: 30px;
  }
}

.imagem-on-off-envio {
  border: 1px solid $primary;
  border-radius: 10px;
  .image-container-on-off {
    height: 250px;
  }
}
