@import '../../../themes/variables.scss';

.summary-informacoes {
  h3 {
    color: $gray;
    font-size: 20px;
  }

  .table {
    td {
      border: none;
      padding: 3px;
      font-size: 14px;
    }

    .table-row-title {
      font-weight: 500;
    }
  }
  .text-format {
    display: flex;
    text-align: justify;
    font-size: 14px;
    padding: 3px;
    margin-right: 3px;
    white-space: pre-wrap;
  }

  .resumo-logo {
    cursor: pointer;
    margin-right: 150px;
    max-width: 100%;
    max-height: 100px;
  }

  .resumo-rodape-img {
    cursor: pointer;
    margin-right: 150px;
    max-width: 100%;
    max-height: 100px;
  }


  .img-layout {
    label {
      display: block;
    }

    img {
      max-width: 100%;
      max-height: 250px;
    }
  }

  .img-arte {
    display: flex;
    flex-direction: row;
    position: relative;

      img {
        display: flex;
        flex-direction: row;
        margin: 5px;
        max-width: 100%;
        max-height: 250px;
      }
  }

  .link-arte{
    cursor: pointer;
  }
  .resumo-grid-produtos {
    font-size: 13px;

    label {
      display: block;
      margin-bottom: 0;
      font-weight: 500;
    }

    .row {
      margin-bottom: 10px;
    }

    .row-striped {
      padding-top: 10px;
      margin: 0;
    }

    .row-striped:nth-of-type(odd) {
      background-color: $gray-lighter;
    }

    .produto-preco {
      font-size: 16px;
      white-space: nowrap;
    }
  }
}
